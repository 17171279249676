import React, { useContext } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
//components
import Product from '../Product';
//context
import { ResultsContext } from '../../../../../context/ResultsContext';
//styles
import {
  AdditionalProductsSection,
  AdditionalProductsContent,
  AdditionalProductsTitle,
  ProductsWrapper,
  ProductsCategoriesNav,
  NavItemCategory,
} from './additionalCategoriesStyles';
import { concatProducts } from '../../../../../services/products';

const AdditionalCategories = ({ id }) => {
  const {
    handleSelectAdditionProduct,
    handleSelectCategory,
    categoriesNavItems,
    allAdditionalCategories,
    additionalCategoryProducts,
    selectedCategory,
  } = useContext(ResultsContext);

  const renderProducts = () => {
    let products;

    selectedCategory === 'viewAll' ? (products = allAdditionalCategories) : (products = additionalCategoryProducts);

    return (
      <div>
        <Swiper
          id="additionalProductsSwiper"
          modules={[Navigation]}
          navigation={{
            nextEl: '.additional-products.swiper-button-next',
            prevEl: '.additional-products.swiper-button-prev',
          }}
          spaceBetween={15}
          slidesPerView={1}
          breakpoints={{
            576: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
          }}
        >
          {products &&
            concatProducts(products).map((product, productIndex) => (
              <SwiperSlide key={productIndex}>
                <Product
                  key={productIndex}
                  id={product.productId}
                  selectProduct={() => handleSelectAdditionProduct(product.categoryId, product)}
                  data={product}
                />
              </SwiperSlide>
            ))}
        </Swiper>
        <div className="additional-products swiper-button-next"></div>
        <div className="additional-products swiper-button-prev"></div>
      </div>
    );
  };

  if (allAdditionalCategories.length > 0)
    return (
      <AdditionalProductsSection id={id}>
        <AdditionalProductsContent>
          <AdditionalProductsTitle>You may also benefit from these</AdditionalProductsTitle>
          <ProductsWrapper>
            <ProductsCategoriesNav id="additionalCategoriesNav">
              <NavItemCategory
                isSelected={selectedCategory === 'viewAll'}
                id="btnViewAllAdditionalProducts"
                className={selectedCategory === 'viewAll' && 'active'}
                onClick={() => handleSelectCategory('viewAll')}
              >
                View all
              </NavItemCategory>
              {categoriesNavItems?.length > 0 &&
                categoriesNavItems.map((category, categoryIndex) => (
                  <NavItemCategory
                    key={categoryIndex}
                    id={category.id}
                    isSelected={selectedCategory === category}
                    className={selectedCategory === category && 'active'}
                    onClick={() => handleSelectCategory(category)}
                  >
                    {category.name}
                  </NavItemCategory>
                ))}
            </ProductsCategoriesNav>

            {renderProducts()}
          </ProductsWrapper>
        </AdditionalProductsContent>
      </AdditionalProductsSection>
    );

  return null;
};

export default AdditionalCategories;
