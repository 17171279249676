import { useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
// components
import Product from '../../Product/Product';
//context
import { ResultsContext } from '../../../../../../context/ResultsContext';
//services
import { formatDesc } from '../../../../../../services/format';
// styles
import {
  Info,
  RecommendedCategoryTitle,
  RecommendedCategoryTitleWrapper,
  RecommendedCategory,
  SwiperWrapper,
} from './categoryStyles';
import InfoIcon from '../../../../../../assets/images/info.svg';

export const ReactTooltipStyled = styled(ReactTooltip)`
  &.type-light.place-top {
    background: #ffffff;
    box-shadow: 0px 4px 4px 0px #00000040;
    font-size: 14px;
    color: #333333;
    max-width: 312px;
    &:after {
      border-top-color: #ffffff;
      z-index: 1;
    }
  }
`;

const Category = ({ data, index }) => {
  const { handleSelectProduct, selectedProducts } = useContext(ResultsContext);

  return (
    <RecommendedCategory id={data.id}>
      <RecommendedCategoryTitleWrapper>
        <RecommendedCategoryTitle>{data.name}</RecommendedCategoryTitle>

        {data.description && data.name === 'Multivitamin' && (
          <>
            <Info src={InfoIcon} data-tip data-for="categoryDesc" globalEventOff="click" data-event="click focus" />
            <ReactTooltipStyled
              className="tooltip"
              id="categoryDesc"
              place="top"
              type="light"
              aria-haspopup="true"
              effect="solid"
            >
              {formatDesc(data.description)}
            </ReactTooltipStyled>
          </>
        )}
      </RecommendedCategoryTitleWrapper>

      <SwiperWrapper>
        <Swiper
          modules={[Navigation]}
          navigation={{
            nextEl: `.recommended-${index}.swiper-button-next`,
            prevEl: `.recommended-${index}.swiper-button-prev`,
          }}
          spaceBetween={15}
          slidesPerView={1}
          breakpoints={{
            576: {
              slidesPerView: data.products.length >= 2 ? 2 : data.products.length,
            },
            768: {
              slidesPerView: data.products.length >= 3 ? 3 : data.products.length,
            },
          }}
        >
          {data.products.length > 0 &&
            data.products.map((product, productIndex) => {
              return (
                <SwiperSlide key={productIndex}>
                  <Product
                    key={productIndex}
                    data={product}
                    id={product.productId}
                    isSelected={selectedProducts[data.id]?.productId === product.productId}
                    selectProduct={() => handleSelectProduct(data, product)}
                  />
                </SwiperSlide>
              );
            })}
        </Swiper>
      </SwiperWrapper>

      <div className={`recommended-${index} swiper-button-next`}></div>
      <div className={`recommended-${index} swiper-button-prev`}></div>
    </RecommendedCategory>
  );
};

export default Category;
