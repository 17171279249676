import styled, { css } from 'styled-components';
import { theme, fonts } from '../../theme';

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  overflow: hidden;
  transition: opacity 0.15s linear;
  z-index: 1050;
  background-color: rgba(0, 0, 0, 0.25);
  ${props =>
    props.isOpen &&
    css`
      z-index: 1072;
      overflow-x: hidden;
      overflow-y: auto;
      display: block;
    `}
`;

export const ModalDialog = styled.div`
  position: relative;
  width: auto;
  max-width: 600px;
  margin: 1.75rem auto;
  pointer-events: none;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  transform: translate(0, -25%);
  ${props =>
    props.isOpen &&
    css`
      transform: translate(0, 0);
    `}
`;

export const ModalContent = styled.div`
  min-height: 208px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 46px;
  width: 100%;
  pointer-events: auto;
  background-color: ${theme.white};
  outline: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
`;

export const CloseCross = styled.img`
  position: absolute;
  top: 16px;
  right: 17px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

export const ModalTitle = styled.h3`
  font-family: ${fonts.secondary};
  color: ${theme.clicksBlue};
  font-size: 24px;
  line-height: 24px;
  font-weight: 400;
  margin-bottom: 23px;
`;

export const ModalDesc = styled.div`
  font-family: ${fonts.primary};
  color: ${theme.textBlack};
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
`;
