import styled, { css } from 'styled-components';
import { fonts, theme } from '../../../../../theme';

export const ProductItem = styled.div`
  height: 100%;
  width: 100%;
  max-width: 232px;
  padding: 18px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  text-align: left;
  position: relative;
  cursor: pointer;
  border: 1px solid transparent;
  box-sizing: border-box;
  font-size: 14px;
  margin: 0 auto;
  border-radius: 10px;
  transition: border 0.15s ease-in;
  ${props =>
    props.isSelected &&
    css`
      border: 1px solid ${theme.green};
    `}
`;

export const ProductImage = styled.img`
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

export const ProductTitle = styled.h4`
  margin-top: 25px;
  text-align: left;
  font-size: 13px;
  font-weight: 600;
  font-family: ${fonts.secondary};
`;

export const ProductDesc = styled.p`
  text-align: left;
  font-size: 13px;
  font-weight: 300;
  font-family: ${fonts.primary};
`;

export const Price = styled.span`
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  font-family: ${fonts.secondary};
  color: ${theme.clicksBlue};
  ${props =>
    props.hasOffer &&
    css`
      color: ${theme.red};
    `}
`;

export const ProductPrice = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 14px;
`;

export const PromotionText = styled.span`
  background-color: ${theme.redBackground};
  color: ${theme.red};
  border-radius: 10px;
  padding: 3px 15px;
  font-size: 12px;
  font-family: ${fonts.primary};
  font-weight: 400;
`;

export const ProductGoals = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
`;

export const ProductGoal = styled.div`
  width: 36px;
  height: 36px;
  margin: 6px;
  padding: 8px;
  border-radius: 50%;
  background-color: #e8f4d9;
  color: #b9338d;
  box-sizing: border-box;
`;

export const ProductGoalIcon = styled.img`
  width: 100%;
`;
