import React from 'react';
import {
  IntroSection,
  IntroContentImages,
  IntroContent,
  IntroContentText,
  IntroUpperText,
  IntroBelowText,
} from './introStyles';
import ConsultationButton from '../partial/ConsultationButton';

const Intro = React.memo(({ id }) => (
  <IntroSection id={id}>
    <IntroContent>
      <IntroContentText>
        <IntroUpperText>
          Which vitamins and <br /> supplements are right for you?
        </IntroUpperText>
        <IntroBelowText>
          The Clicks Supplement Recommender uses your health and lifestyle inputs to provide personalised supplement
          suggestions to help support your wellness goals.
        </IntroBelowText>
        <ConsultationButton />
      </IntroContentText>
      <IntroContentImages />
    </IntroContent>
  </IntroSection>
));

export default Intro;
