import React, { useContext } from 'react';
import {
  IntroSection,
  IntroOverlay,
  IntroContent,
  IntroText,
  IntroTitle,
  IntroDescription,
  IntroGoals,
} from './introStyles';
import { Goal, Goals, Image } from '../../../Landing/improveStyles';
import { ResultsContext } from '../../../../../context/ResultsContext';

const Intro = ({ id }) => {
  const { goals, errorGetHealthProfile } = useContext(ResultsContext);

  const renderGoals = () => (
    <IntroGoals>
      <Goals>
        {goals?.map((goal, i) => (
          <Goal key={i} background={goal.backgroundColor} color={goal.color}>
            {goal.name}
            <Image src={goal.iconUrl} />
          </Goal>
        ))}
      </Goals>
    </IntroGoals>
  );

  return errorGetHealthProfile ? (
    <></>
  ) : (
    <IntroSection id={id}>
      <IntroOverlay></IntroOverlay>

      <IntroContent>
        {goals?.length > 0 ? (
          <IntroText>
            <IntroTitle>Your results</IntroTitle>
            <IntroDescription>
              Your answers suggest that the following goals are important to you and your wellbeing.
            </IntroDescription>
          </IntroText>
        ) : (
          <IntroText>
            <IntroTitle>Sorry something went wrong - please try again later</IntroTitle>
          </IntroText>
        )}

        {goals?.length > 0 && renderGoals()}
      </IntroContent>
    </IntroSection>
  );
};

export default Intro;
