import styled, { css } from 'styled-components';
import { fonts, theme } from '../../../../../theme';

export const RecommendSection = styled.section`
  padding-top: 60px;
  background-color: ${theme.white};
`;

export const RecommendContent = styled.div`
  text-align: center;
  max-width: 1086px;
  margin: 0 auto;
`;

export const RecommendSectionTitle = styled.h1`
  font-family: ${fonts.secondary};
  font-family: 24px;
  font-weight: 400;
  color: ${theme.clicksBlue};
`;

export const RecommendDesc = styled.p`
  margin-bottom: 72px;
  font-family: ${fonts.primary};
  font-size: 14px;
  font-weight: 400;
  color: ${theme.textBlack};
`;

export const RecommendItems = styled.div`
  box-shadow: 0px 1px 3px 0px #00000026;
  border-radius: 10px;
  padding: 66px 120px;
  background-color: ${theme.white};
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const RecommendedItem = styled.div`
  width: 100%;
  max-width: 194px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  text-align: left;
  padding: 18px;
  position: relative;
  cursor: pointer;
  border: 1px solid transparent;
  ${props =>
    props.active &&
    css`
      border: 1px solid ${theme.green};
      border-radius: 10px;
    `}
`;

export const RecommendedItemImage = styled.img`
  width: 60%;
  margin-left: auto;
  margin-right: auto;
`;

export const RecommendedItemPrice = styled.p`
  text-align: left;
  margin-top: 24px;
  margin-bottom: 14px;
  font-size: 16px;
  font-weight: 600;
  font-family: ${fonts.secondary};
  color: ${theme.clicksBlue};
  ${props =>
    props.hasDiscount &&
    css`
      color: ${theme.red};
    `}
`;

export const DiscountBadge = styled.img`
  position: absolute;
  top: 4px;
  left: 4px;
`;
export const RecommendedCategoriesItems = styled.div``;
