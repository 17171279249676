import styled, { css } from 'styled-components';
import { fonts, theme } from '../../../../../theme';

export const AdditionalProductsSection = styled.section``;

export const AdditionalProductsContent = styled.div`
  text-align: center;
  max-width: 1086px;
  margin: 0 auto;
`;

export const AdditionalProductsTitle = styled.h1`
  font-size: 24px;
  font-weight: 600;
  font-family: ${fonts.secondary};
  margin-bottom: 25px;
  color: ${theme.clicksBlue};
`;

export const ProductsWrapper = styled.div`
  box-sizing: border-box;
  border-radius: 10px;
  padding: 23px 24px 34px;
  background-color: ${theme.white};
  position: relative;
  margin: 15px;
  margin-bottom: 86px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
`;

export const ProductsCategoriesNav = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  overflow-x: auto;
  max-width: 100%;
`;

export const NavItemCategory = styled.div`
  font-size: 13px;
  font-family: ${fonts.secondary};
  color: ${theme.borderGreen};
  height: 34px;
  width: 167px;
  background-color: ${theme.lightGreen};
  border-radius: 17px;
  margin: 7px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 10px;
  ${props =>
    props.isSelected &&
    css`
      color: ${theme.clicksBlue};
    `}
`;
