import styled from 'styled-components';
import { sectionTitle, theme, containers } from '../../../theme';
import { media } from '../../../utilities/grid';

export const FaqSection = styled.section`
  padding-top: 63px;
  padding-bottom: 73px;
  background-color: ${theme.white};
`;

export const FaqContent = styled.div`
  margin: 0 auto;
  padding: 0 30px;
  max-width: ${containers.Lg};

  ${media.from.screenXl`
     max-width: ${containers.Xl};
  `}

  ${media.from.screenXxl`
    max-width: ${containers.Xxl};
  `}
`;

export const FaqHeading = styled(sectionTitle)`
  text-align: center;
  margin-bottom: 37px;
`;

export const FaqQuestions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const FaqQuestionItem = styled.div`
  width: 100%;
  margin-bottom: 47px;
  text-align: center;
  padding: 0 15px;
  ${media.from.screenXl`
    max-width: 360px;
    text-align:left;
  `}
  ${media.from.screenXxl`
    margin-bottom: 0;
    padding: 0;
  `}
`;

export const Question = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 18px;
`;

export const Answer = styled.p`
  font-size: 14px;
  font-weight: 300;
`;
