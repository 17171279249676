export const calcNutrientsOnSet = (nutrient, basketNutrients) => {
  let basketNutrientsTemp = [...basketNutrients];
  const findInBasket = basketNutrientsTemp.find(n => n.id === nutrient.id);

  if (!findInBasket) {
    basketNutrientsTemp.push(nutrient);
  } else {
    const combinedNutrientValue = +(nutrient.value + findInBasket.value).toFixed(2);
    const nutrientIndex = basketNutrientsTemp.findIndex(n => n.id === nutrient.id);
    basketNutrientsTemp[nutrientIndex] = { ...basketNutrientsTemp[nutrientIndex], value: combinedNutrientValue };
  }
  return basketNutrientsTemp;
};

export const setNutrients = (product, basketNutrients) => {
  let basketNutrientsTemp = [...basketNutrients];

  for (let i = 0; i < product.nutrients.length; i++) {
    const nutrient = product.nutrients[i];
    if (nutrient.value) {
      basketNutrientsTemp = calcNutrientsOnSet(nutrient, basketNutrientsTemp);
    }
  }

  return basketNutrientsTemp;
};

export const calcNutrientsOnRemove = (nutrient, basketNutrients) => {
  let basketNutrientsTemp = [...basketNutrients];
  const findInBasket = basketNutrientsTemp.find(n => n.id === nutrient.id);
  const nutrientIndex = basketNutrientsTemp.findIndex(n => n.id === nutrient.id);

  if (findInBasket?.value && nutrient?.value) {
    const deductedNutrientValue = +(findInBasket.value - nutrient.value).toFixed(2);
    if (0 >= deductedNutrientValue) {
      basketNutrientsTemp.splice(nutrientIndex, 1);
    } else {
      basketNutrientsTemp[nutrientIndex] = { ...basketNutrientsTemp[nutrientIndex], value: deductedNutrientValue };
    }
  }
  return basketNutrientsTemp;
};

export const removeBasketNutrients = (product, basketNutrients) => {
  let basketNutrientsTemp = [...basketNutrients];
  for (let i = 0; i < product.nutrients.length; i++) {
    let nutrient = product.nutrients[i];
    let resultCalcNutrientsOnRemove = [...calcNutrientsOnRemove(nutrient, basketNutrientsTemp)];
    basketNutrientsTemp = [...resultCalcNutrientsOnRemove];
  }
  return basketNutrientsTemp;
};

export const shouldShowTULModal = (nutrient, basketNutrients, nutrientUpperLimits) => {
  const findInConfig = nutrientUpperLimits.find(n => n.id === nutrient.id);
  const findInBasket = basketNutrients.find(n => n.id === nutrient.id);

  if (!findInConfig?.value) {
    return false;
  } else {
    if (!findInBasket) {
      return nutrient.value > findInConfig.value;
    } else {
      const combinedNutrientValue = nutrient.value + findInBasket.value;
      return combinedNutrientValue > findInConfig.value;
    }
  }
};

export const shouldAddProduct = (product, basketNutrients, nutrientUpperLimits) => {
  for (let i = 0; i < product.nutrients.length; i++) {
    const nutrient = product.nutrients[i];
    if (nutrient.value) {
      const resultShouldShowTulModal = shouldShowTULModal(nutrient, basketNutrients, nutrientUpperLimits);
      if (resultShouldShowTulModal) {
        return { result: !resultShouldShowTulModal, errorNutrient: nutrient.name };
      }
    }
  }
  return true;
};

export const concatProducts = data => {
  let arr = [];
  if (data.products) {
    data.products.map(product => arr.push({ ...product, categoryId: data.id }));
  } else {
    data.map(category => category.products.map(product => arr.push({ ...product, categoryId: category.id })));
  }
  return arr;
};

export const scrollToCategory = ref => {
  window.scrollTo({
    top: ref.current?.offsetHeight + 650,
    behavior: 'smooth',
  });
};
