import * as rudderanalytics from 'rudder-sdk-js';

export const isSSR = typeof window === 'undefined';

export const initialiseRudderAnalytics = () => {
  const WRITE_KEY = process.env.REACT_APP_RUDDERSTACK_PROD_WRITE_KEY;
  const DATA_PLANE_URL = process.env.REACT_APP_RUDDERSTACK_SERVER_URL;

  rudderanalytics.load(WRITE_KEY, DATA_PLANE_URL);
};

export const trackIdentify = (id, options) => {
  if (!isSSR && rudderanalytics) {
    rudderanalytics.identify(id, options);
  }
};

export const trackPageView = (category, name, utmParams) => {
  if (!isSSR && rudderanalytics) {
    rudderanalytics.page(category, name, utmParams);
  }
};

export const trackEvent = (name, options) => {
  if (!isSSR && rudderanalytics) {
    rudderanalytics.track(name, options);
  }
};
