export const SET_NUTRIENTS = 'SET_NUTRIENTS';
export const SET_HEALTH_PROFILE = 'SET_HEALTH_PROFILE';
export const SET_RECOMMENDED_CATEGORIES = 'SET_RECOMMENDED_CATEGORIES';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const REMOVE_NUTRIENTS = 'REMOVE_NUTRIENTS';
export const SELECT_PRODUCT = 'SELECT_PRODUCT';
export const DESELECT_PRODUCT = 'DESELECT_PRODUCT';
export const SWITCH_PRODUCT = 'SWITCH_PRODUCT';
export const SELECT_ADDITIONAL_PRODUCT = 'SELECT_ADDITIONAL_PRODUCT';
export const SELECT_CATEGORY = 'SELECT_CATEGORY';
