export const formatPrice = price => `R ${price.toFixed(2)}`;

export const textCapitalize = text =>
  text
    .split(' ')
    .map(word => word[0].toUpperCase() + word.substring(1))
    .join(' ');

export const isEmpty = obj => obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;

export const formatDesc = desc => desc.split(/\\n/g).map((str, i) => <div key={i}>{str}</div>);
