import styled from 'styled-components';

import { theme, containers, fonts } from '../../../theme';
import { media } from '../../../utilities/grid';

export const MainHeader = styled.header`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: ${containers.Xxl};

  ${media.from.screenXl`
    max-width: ${containers.Xl};
  `}

  ${media.from.screenXxl`
    max-width: ${containers.Xxl};
  `}
`;

export const MainBanner = styled.div`
  background-color: #cc0033;
  color: white;
  padding: 1.25rem;
  text-align: center;
`;

export const HeaderContent = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 20px;
  ${media.from.screenXl`
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
  `}
`;

export const LogoSrc = styled.img`
  height: 70px;
  margin-left: 0;
  margin-bottom: 15px;
  ${media.from.screenXl`
    height: 122px;
    margin-bottom: 0;
  `}
`;

export const FooterComponent = styled.footer`
  background: ${theme.clicksBlue};
  padding-top: 30px;
  padding-bottom: 30px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
`;

export const FooterLink = styled.a`
  color: ${theme.white};
  margin: 0 6px;
  text-decoration: underline;
`;

export const FooterContent = styled.div`
  max-width: ${containers.Xxl};
  padding: 0 25px;
  color: ${theme.white};
  font-size: 14px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  font-family: ${fonts.primary};
`;

export const FooterLinks = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PhoneNumber = styled.a`
  color: ${theme.white};
  font-weight: 600;
`;

export const CustomerServiceCenter = styled.div``;

export const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  background-color: ${theme.blueBackground};
`;
