import React, {useContext, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
//components
import Loader from '../partial/Loader';
import Header from '../partial/Header';
import Intro from './components/Intro';
import SelectedProducts from './components/SelectedProducts';
import RecommendedCategories from './components/RecommendedCategories';
import AdditionalCategories from './components/AdditionalCategories';
// context
import {ResultsContext} from '../../../context/ResultsContext';
// import { AuthContext } from '../../../context/AuthContext';
//services
import {getCookie} from '../../../services/cookies';
import {paramResultId} from '../../../services/global';
//styles
import {ResultPageWrapper} from './styles';
import {COOKIES} from '../../../constants/cookies';
import {useTracking} from "../../../hooks/useTracking";
import {useTitle} from "../../../services/useHooks";

const Result = () => {

  useTitle('Clicks Supplement Recommender Results');

  const navigate = useNavigate();

  const {trackResultPageView, hasTrackedEvent} = useTracking();

  const {
    getHealthProfile,
    getHealthProfileHistorical,
    loadingHealthProfile,
    goals
  } = useContext(ResultsContext);

  // const { loadingUserInfo } = useContext(AuthContext);

  useEffect(() => {
    if (!hasTrackedEvent.resultPageViewed) {
      trackResultPageView()
    }
  });

  useEffect(() => {
    if (!paramResultId) {
      if (!getCookie(COOKIES.token)) navigate('/');
      getHealthProfile();
    } else {
      getHealthProfileHistorical({
        variables: {
          chatId: paramResultId,
        },
      });
    }
  }, [getHealthProfile, getHealthProfileHistorical, navigate]);

  // const loading = loadingUserInfo || loadingHealthProfile;

  if (loadingHealthProfile) return <Loader/>;

  return (
    <ResultPageWrapper>
      <Header id="resultHeader"/>
      <Intro id="resultIntroSection"/>

      {goals?.length > 0 && (
        <>
          <RecommendedCategories id="recommendedCategoriesSection"/>
          <AdditionalCategories id="additionalCategoriesSection"/>
          <SelectedProducts id="selectedProductsSection"/>
        </>
      )}
    </ResultPageWrapper>
  );
};

export default Result;
