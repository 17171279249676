import axios from 'axios';

const client = axios.create({
  baseURL: process.env.REACT_APP_X_API,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_X_API_KEY,
  },
});

export default client;
