import React from 'react';
import {
  HowItWorksSection,
  HowItWorksContent,
  HowItWorksCardWrapper,
  HowItWorksTextWrapper,
  HowItWorksTextContent,
  TextTitle,
  TextSteps,
  Step,
  StepIcon,
  StepText,
  StepTitle,
  StepDesc,
  HowItWorksCard,
  CardHeader,
  ProfileImage,
  HeaderText,
  CardBody,
  ProfileName,
  Goal,
  GoalIcon,
  CardTextMd,
  CardTextSm,
  CardTextXs,
  ItemImage,
  RecommendItems,
  RecommendItem,
} from './howItWorksStyles';
import TakeTheQuiz from '../../../assets/images/take_the_quiz.svg';
import ViewYourResults from '../../../assets/images/view_your_results.svg';
import SelectYourBundle from '../../../assets/images/select_your_bundle.svg';
import ConsultationButton from '../partial/ConsultationButton';
import ProfileImg from '../../../assets/images/profile_image.png';
import ProductItem1 from '../../../assets/images/recommend_product_1@2x.png';
import ProductItem2 from '../../../assets/images/recommend_product_2@2x.png';
import ProductItem3 from '../../../assets/images/recommend_product_3@2x.png';
import Stress from '../../../assets/images/stress.svg';

const StepsItems = [
  {
    title: 'Take the assessment ',
    description:
      'Answer questions about your health and lifestyle to help us create a suggestion tailored to your wellness goals.',
    icon: TakeTheQuiz,
  },
  {
    title: 'View your results',
    description: 'Find out which vitamins and supplements support your wellness goals.',
    icon: ViewYourResults,
  },
  {
    title: 'Select your bundle',
    description: 'Place your order and receive FREE delivery for orders over R550.',
    icon: SelectYourBundle,
  },
];

const HowItWorks = React.memo(({ id }) => (
  <HowItWorksSection id={id}>
    <HowItWorksContent>
      <HowItWorksTextWrapper>
        <HowItWorksTextContent>
          <TextTitle>How it works</TextTitle>
          <TextSteps>
            {StepsItems.map((step, i) => (
              <Step key={i}>
                <StepIcon src={step.icon} />
                <StepText>
                  <StepTitle>{step.title}</StepTitle>
                  <StepDesc>{step.description}</StepDesc>
                </StepText>
              </Step>
            ))}
          </TextSteps>
          <ConsultationButton />
        </HowItWorksTextContent>
      </HowItWorksTextWrapper>
      <HowItWorksCardWrapper>
        <HowItWorksCard>
          <CardHeader>
            <ProfileImage src={ProfileImg} />
            <HeaderText>
              <ProfileName>Carmen, 32</ProfileName>
              <Goal background={'#F1D6E8'} color={'#B9338D'}>
                Stress & Anxiety
                <GoalIcon src={Stress} />
              </Goal>
            </HeaderText>
          </CardHeader>
          <CardBody>
            <CardTextMd>Carmen’s recommendations</CardTextMd>
            <RecommendItems>
              <RecommendItem>
                <ItemImage src={ProductItem1} />
                <CardTextSm>Stress away</CardTextSm>
                <CardTextXs>Anxiety & Stress 60 Tablets</CardTextXs>
              </RecommendItem>
              <RecommendItem>
                <ItemImage src={ProductItem2} />
                <CardTextSm>Clicks expert</CardTextSm>
                <CardTextXs>5-HTP 110mg 30 Capsules</CardTextXs>
              </RecommendItem>
              <RecommendItem>
                <ItemImage src={ProductItem3} />
                <CardTextSm>GNC</CardTextSm>
                <CardTextXs>GABA Dietary Supplement</CardTextXs>
              </RecommendItem>
            </RecommendItems>
          </CardBody>
        </HowItWorksCard>
      </HowItWorksCardWrapper>
    </HowItWorksContent>
  </HowItWorksSection>
));

export default HowItWorks;
