import styled, { keyframes, css } from 'styled-components';
import { theme, fonts, bodyText, bodyTitleSmall, bodySmallText, containers } from '../../../theme';
import { media } from '../../../utilities/grid';
import CrossIcon from '../../../assets/images/cross.svg';

export const SectionContent = styled.div`
  max-width: ${containers.Xxl};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${theme.bootsBlue};
  text-align: left;
  padding: 1rem;
`;

export const ThisText = styled(bodyText)`
  line-height: 1.5;
  text-align: center;
  ${media.from.screenXl`
    max-width: 50%;
  `}
`;

export const ResultPageWrapper = styled.div`
  position: relative;
  padding-bottom: 90px;
`;

export const AddOnsContent = styled(SectionContent)``;

export const BadgesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem;
  margin: 1rem 0;
  justify-content: center;
  align-items: center;
`;

export const ProductCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 2rem 0;
  width: 100%;
`;

export const CardImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 1px solid ${theme.lightGrey};
  padding: 0.2rem;
`;

export const CardImage = styled.img`
  max-width: 100%;
  height: 9.5rem;
  ${media.from.screenXl`
    height: 18.35rem;
  `}
`;

export const CardIconContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 0.3rem;
  margin-top: 0.5rem;
`;

export const CardIcon = styled.img`
  width: 1.1rem;
  height: 1.1rem;
  max-width: 1.1rem;
  margin-left: 0.4rem;
  ${media.from.screenXl`
    width: 1.6rem;
    height: 1.6rem;
    max-width: 1.6rem;
    margin-left: 0.6rem;
  `}
`;

export const CardInfoWrapper = styled.div`
  padding: 0.25rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
`;

export const InfoTitle = styled(bodyTitleSmall)``;

export const InfoUnit = styled(bodySmallText)``;

export const InfoPrice = styled(bodySmallText)`
  font-weight: bold;

  ${props =>
    props.hasOffer &&
    `
    text-decoration: line-through;
  `}
`;

export const DiscountCode = styled.p`
  padding: 0.25rem 0.5rem;
  margin-bottom: 1rem;
  color: ${theme.bootsBlue};
  background-color: ${theme.greenBlue};
  border-radius: 5px;
`;

const spin = keyframes`
  to {
    transform: rotate(359deg);
  }
`;

const grow = keyframes`
 to {
    width: 16px;
    height: 16px;
    margin-top: -8px;
    right: 13px;
  }
`;

export const CheckoutButton = styled.a`
  white-space: nowrap;
  height: 40px;
  background: ${theme.clicksBlue};
  padding: 0 32px;
  color: ${theme.white};
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  font-family: ${fonts.secondary};
  font-size: 16px;
  position: relative;
  transition: padding 0.15s linear;
  ${props =>
    props.isLoading &&
    css`
      padding-right: 40px;
      cursor: default;
      opacity: 0.75;
      pointer-events: none;
      &:after {
        content: '';
        position: absolute;
        border-radius: 100%;
        right: 6px;
        top: 51%;
        width: 0px;
        height: 0px;
        border: 2px solid rgba(255, 255, 255, 0.5);
        border-left-color: #fff;
        border-top-color: #fff;
        animation: ${spin} 0.6s infinite linear, ${grow} 0.3s forwards ease-out;
      }
    `}
`;

export const SubmitButton = styled.button`
  white-space: nowrap;
  height: 40px;
  background: ${theme.clicksBlue};
  padding: 0 32px;
  color: ${theme.white};
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  font-family: ${fonts.secondary};
  font-size: 16px;
  position: relative;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 1.25rem;
  right: 1.25rem;
  width: 1.25rem;
  height: 1.25rem;
  background: url(${CrossIcon}) no-repeat;
  border: none;
  cursor: pointer;
`;
