import React, { useContext } from 'react';
// components
import Category from './Category';
// styles
import {
  RecommendContent,
  RecommendDesc,
  RecommendSection,
  RecommendSectionTitle,
  RecommendedCategoriesItems,
} from './recommendedCategoriesStyles';
import { RecommendedCategory, ErrorMessage } from './Category/categoryStyles';
// context
import { ResultsContext } from '../../../../../context/ResultsContext';

const RecommendedCategories = ({ id }) => {
  const { recommendedCategories, errorGetHealthProfile, recommendedCategoriesRef } = useContext(ResultsContext);

  return (
    <RecommendSection id={id}>
      <RecommendContent>
        {errorGetHealthProfile ? (
          <RecommendedCategory>
            <ErrorMessage>Sorry, we're experiencing high load right now - please try again later</ErrorMessage>
          </RecommendedCategory>
        ) : recommendedCategories.length > 0 ? (
          <>
            <RecommendSectionTitle>Recommended for you</RecommendSectionTitle>
            <RecommendDesc>Select one product from each group to complete your personalised bundle.</RecommendDesc>
            <RecommendedCategoriesItems ref={recommendedCategoriesRef}>
              {recommendedCategories.map((category, categoryIndex) => (
                <Category key={categoryIndex} data={category} index={categoryIndex} />
              ))}
            </RecommendedCategoriesItems>
          </>
        ) : (
          <RecommendedCategory>
            <ErrorMessage>
              We don't have any recommendations for you in stock right now, please try again later.
            </ErrorMessage>
          </RecommendedCategory>
        )}
      </RecommendContent>
    </RecommendSection>
  );
};

export default RecommendedCategories;
