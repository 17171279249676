import React, {useContext} from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import {VitlConsultation} from 'vitl-consultation';
import {GlobalContext} from '../../../context/GlobalContext';
import {AuthContext} from '../../../context/AuthContext';
import {MainHeader, LogoSrc, HeaderContent} from './partialStyles';
import {ButtonSecondary} from '../../../AppStyles';
import {getCookie, removeCookie, setCookie} from '../../../services/cookies';
import LogoImage from '../../../assets/images/suppliment-recommender.svg';
import {COOKIES} from '../../../constants/cookies';
import useGoogleAnalytics from "../../../hooks/useGoogleAnalytics";

const API_KEY = process.env.REACT_APP_X_API_KEY;
const API_URI = process.env.REACT_APP_X_API;

const Header = ({id}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {trackEvent} = useGoogleAnalytics();

  const {showConsultation, setShowConsultation} = useContext(GlobalContext);
  const {createGuest, getUserVitlInfo} = useContext(AuthContext);

  const handleViewResults = () => {
    trackEvent('wellness_clicks', 'Consultation_finished')
    navigate('/result');
  };

  const handleMarketingConsentCallback = () => {
    trackEvent('wellness_clicks', 'Marketing_Consent_Given')
  }

  const handleGoToLoginCallback = () => {
    removeCookie(COOKIES.token);
    removeCookie(COOKIES.guestToken);
  };

  const handleDeleteUserDataCallback = () => {
    removeCookie(COOKIES.token);
    removeCookie(COOKIES.guestToken);
    setShowConsultation(false);
    createGuest().then(() => {
      setShowConsultation(true);
    });
  };

  const handleGetTokenCallback = () => {
    return getCookie(COOKIES.token);
  };

  const handleGetGuestTokenCallback = () => {
    return getCookie(COOKIES.guestToken);
  };

  const handleSetTokenCallback  = (token) => {
    setCookie(COOKIES.token, token, 5 * 60 * 60);
    getUserVitlInfo();
  };

  return (
    <MainHeader id={id}>
      <HeaderContent>
        <Link to={'/'}>
          <LogoSrc id="logo" src={LogoImage}/>
        </Link>
        {location.pathname === '/result' && (
          <ButtonSecondary href={'https://clicks.co.za/'} target="_blank">
            Back to Clicks website
          </ButtonSecondary>
        )}
      </HeaderContent>
      {showConsultation && <VitlConsultation
        apiUrl={API_URI}
        apiKey={API_KEY}
        isOverlayOpen={showConsultation}
        viewResultsCallback={()=> handleViewResults()}
        closeConsultationCallback={() => setShowConsultation(false)}
        goToLoginCallback={() => handleGoToLoginCallback()}
        deleteUserDataCallback={() => handleDeleteUserDataCallback()}
        getTokenCallback={() => handleGetTokenCallback()}
        getGuestTokenCallback={() => handleGetGuestTokenCallback()}
        setTokenCallback={(token)=> handleSetTokenCallback(token)}
        marketingConsentCallback={()=> handleMarketingConsentCallback()}
      />}
    </MainHeader>
  );
};

export default Header;
