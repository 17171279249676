import {
  ADD_PRODUCT,
  DESELECT_PRODUCT,
  REMOVE_NUTRIENTS,
  SELECT_ADDITIONAL_PRODUCT,
  SELECT_CATEGORY,
  SELECT_PRODUCT,
  SET_HEALTH_PROFILE,
  SET_NUTRIENTS,
  SET_RECOMMENDED_CATEGORIES,
  SWITCH_PRODUCT,
} from './types';
import { removeBasketNutrients, setNutrients } from '../services/products';

const ResultsReducer = (state, action) => {
  const {
    basketNutrients,
    config,
    recommendedCategories,
    selectedCategory,
    selectedProducts,
    allAdditionalCategories,
    categoriesNavItems,
  } = state;
  const { nutrientUpperLimits } = config;

  switch (action.type) {
    case SET_HEALTH_PROFILE: {
      const { config, healthProfile } = action.payload;

      return {
        ...state,
        config: { ...config },
        healthProfile: { ...healthProfile },
      };
    }
    case SET_RECOMMENDED_CATEGORIES: {
      const { recommendedCategories, basketNutrients, selectedProducts, allAdditionalCategories, categoriesNavItems } =
        action.payload;

      return {
        ...state,
        recommendedCategories: [...recommendedCategories],
        selectedProducts: { ...selectedProducts },
        allAdditionalCategories: [...allAdditionalCategories],
        categoriesNavItems: [...categoriesNavItems],
        basketNutrients: [...basketNutrients],
      };
    }
    case SET_NUTRIENTS:
      return {
        ...state,
        basketNutrients: [...setNutrients(action.payload, basketNutrients, nutrientUpperLimits)],
      };
    case REMOVE_NUTRIENTS:
      return {
        ...state,
        basketNutrients: [...removeBasketNutrients(action.payload, basketNutrients)],
      };
    case ADD_PRODUCT: {
      const { product, category } = action.payload;

      return {
        ...state,
        recommendedCategories: [...recommendedCategories, category],
        basketNutrients: [...setNutrients(product, basketNutrients, nutrientUpperLimits)],
      };
    }
    case SELECT_PRODUCT: {
      const { product, categoryId } = action.payload;

      return {
        ...state,
        selectedProducts: { ...selectedProducts, [categoryId]: product },
        basketNutrients: [...setNutrients(product, basketNutrients, nutrientUpperLimits)],
      };
    }
    case DESELECT_PRODUCT: {
      const { product, category } = action.payload;
      let selectedProductsTemp = { ...state.selectedProducts };
      delete selectedProductsTemp[category.id];

      return {
        ...state,
        selectedProducts: { ...selectedProductsTemp },
        basketNutrients: [...removeBasketNutrients(product, basketNutrients)],
      };
    }
    case SWITCH_PRODUCT: {
      const { prevProduct, nextProduct, categoryId } = action.payload;
      let selectedProductsTemp = { ...state.selectedProducts };
      delete selectedProductsTemp[categoryId];
      let basketNutrientsTemp = [...removeBasketNutrients(prevProduct, basketNutrients)];

      return {
        ...state,
        selectedProducts: { ...selectedProductsTemp, [categoryId]: nextProduct },
        basketNutrients: [...setNutrients(nextProduct, basketNutrientsTemp, nutrientUpperLimits)],
      };
    }
    case SELECT_ADDITIONAL_PRODUCT: {
      const { product, categoryId } = action.payload;
      const findCategoryOfProduct = (category, productId) =>
        category.products.find(product => product.id === productId);
      const selected = allAdditionalCategories.find(category => findCategoryOfProduct(category, product.id));
      const category = selectedCategory === 'viewAll' ? selected : selectedCategory;

      return {
        ...state,
        selectedProducts: { ...selectedProducts, [categoryId]: product },
        allAdditionalCategories: [...allAdditionalCategories.filter(c => c.id !== category.id)],
        categoriesNavItems: [...categoriesNavItems.filter(c => c.id !== category.id)],
        recommendedCategories: [...recommendedCategories, category],
        basketNutrients: [...setNutrients(product, basketNutrients, nutrientUpperLimits)],
        selectedCategory: selectedCategory !== 'viewAll' ? 'viewAll' : selectedCategory,
      };
    }
    case SELECT_CATEGORY: {
      const { category } = action.payload;
      const findCategory = allAdditionalCategories.find(c => c.id === category.id);
      const additionalCategoryProductsTemp = category === 'viewAll' ? allAdditionalCategories : findCategory;

      return {
        ...state,
        selectedCategory: category,
        additionalCategoryProducts: additionalCategoryProductsTemp,
      };
    }
    default:
      return state;
  }
};

export default ResultsReducer;
