import React, {useContext} from 'react';
//styles
import {SelectedProductsWrapper, SelectedItemsText, NumberOfItems, ItemsTotalPrice} from './selectedProductsStyles';
import {SubmitButton} from '../../styles';
//context
import {ResultsContext} from '../../../../../context/ResultsContext';
//services
import {formatPrice} from '../../../../../services/format';
import {useTracking} from "../../../../../hooks/useTracking";

const SelectedProducts = ({id}) => {
  const {selectedProducts} = useContext(ResultsContext);
  const {hasTrackedEvent, trackEvent, setHasTrackedEvent} = useTracking();

  const totalCost = Object.values(selectedProducts)
    .map(item => item.offerPrice || item.rrpPrice)
    .reduce((prev, curr) => prev + curr, 0);

  const selectedProductsArr = Object.values(selectedProducts);

  const items = selectedProductsArr
    .map(
      product =>
        `<item><amount><![CDATA[1]]></amount><productid><![CDATA[${product.productId}]]></productid><price><![CDATA[${
          product.offerPrice || product.rrpPrice
        }]]></price><name></name><description></description></item>`
    )
    .join('');

  const handleFormOnSubmit = () => {
    if (selectedProductsArr.length > 0 && !hasTrackedEvent.selectedProducts) {
      console.log('submit')
      const selectedProductIds = selectedProductsArr.map(p => p.productId);
      trackEvent('Wellness Selected Products', {
        product_ids: selectedProductIds
      })
      setHasTrackedEvent({...hasTrackedEvent, selectedProducts: true})
    }
  }

  if (selectedProductsArr.length > 0) {
    return (
      <SelectedProductsWrapper id={id}>
        <SelectedItemsText>
          You have selected{' '}
          <NumberOfItems id="totalNumberOfProducts">{Object.values(selectedProducts).length}</NumberOfItems> items with
          total cost of <ItemsTotalPrice id="totalCostOfProducts">{formatPrice(totalCost)}</ItemsTotalPrice>
        </SelectedItemsText>

        <form
          onSubmit={() => handleFormOnSubmit()}
          action={process.env.REACT_APP_X_CLICKS_ADD_TO_CART_URL}
          method="POST"
          target="_blank"
        >
          <input type="hidden" value={`<shop>${items}</shop>`} name="basket"/>
          <SubmitButton type="submit">Add items to basket</SubmitButton>
        </form>
      </SelectedProductsWrapper>
    );
  } else {
    return null;
  }
};

export default SelectedProducts;
