import React, {useState} from 'react';

export const GlobalContext = React.createContext(null);

export const GlobalContextProvider = props => {
  const [showConsultation, setShowConsultation] = useState(false);
  const [modal, setModal] = useState({
    show: false,
    message: '',
    title: '',
  });

  const [hasTrackedEvent, setHasTrackedEvent] = useState({
    landingPageViewed: false,
    resultPageViewed: false,
    recommendedProducts: false,
    selectedProducts: false,
  });

  return (
    <GlobalContext.Provider
      value={{
        hasTrackedEvent,
        setHasTrackedEvent,
        showConsultation,
        setShowConsultation,
        modal,
        setModal,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};
