import styled, { css } from 'styled-components';
import { sectionTitle, theme, fonts } from '../../../theme';
import { media } from '../../../utilities/grid';

export const HowItWorksSection = styled.section`
  background-color: ${theme.lightGreenBackground};
  padding-top: 112px;
  padding-bottom: 108px;
  position: relative;
  overflow-x: hidden;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    border-style: solid;
    border-color: transparent;
    border-width: 5vh 50vw;
    border-top-color: white;
    border-right-color: white;
  }
`;

export const HowItWorksContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 1130px;
  margin: 0 auto;
  padding: 0 25px;
`;

export const HowItWorksTextWrapper = styled.div`
  color: ${theme.bootsBlue};
  width: 100%;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.from.screenXl`
    width: 50%;
    text-align: left;
    align-items: flex-start;
  `}
`;

export const HowItWorksTextContent = styled.div`
  max-width: 446px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${media.from.screenXl`
    align-items: flex-start;
  `}
`;

export const TextTitle = styled(sectionTitle)`
  margin-bottom: 43px;
`;

export const TextSteps = styled.div`
  margin-bottom: 42px;
`;

export const Step = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 42px;
`;

export const StepIcon = styled.img`
  margin-right: 22px;
`;

export const StepText = styled.div``;

export const StepTitle = styled.h3`
  color: ${theme.textBlueSecondary};
  font-family: ${fonts.secondary};
  font-weight: 400;
  font-size: 24px;
`;

export const StepDesc = styled.p`
  color: ${theme.textBlackSecondary};
  font-size: 16px;
  font-weight: 300;
`;

export const HowItWorksCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  margin-top: 58px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  ${media.from.screenXl`
    margin-top: 0;
    width: 50%;
    padding-left: 34px;
  `}
`;

export const HowItWorksCard = styled.div`
  height: auto;
  width: 100%;
  max-width: 532px;
  box-shadow: 0px 0px 14px 0px #00000026;
  background-color: ${theme.white};
  border-radius: 14px;
  ${media.from.screenXl`
    height: 409px;
  `}
`;

export const CardHeader = styled.div`
  height: auto;
  background: ${theme.blueCardBackground};
  padding: 15px 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 15px 15px 0 0;
  overflow: contain;
  font-family: ${fonts.secondary};
  ${media.from.screenXl`
    height: 166px;
    flex-direction: row;
    justify-content: flex-start;
  `}
`;

export const ProfileImage = styled.img`
  width: 136px;
  height: 136px;
  margin-right: 0;
  margin-bottom: 20px;
  ${media.from.screenXl`
    margin-bottom: 0;
    margin-right: 52px;
 `}
`;

export const HeaderText = styled.div``;

export const ProfileName = styled.h4`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
  ${media.from.screenXl`
    text-align: left;
 `}
`;

export const Goal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 166px;
  height: 35px;
  border-radius: 32px;
  padding-left: 15px;
  padding-right: 10px;
  font-size: 12px;
  font-weight: 600;
  box-shadow: 0px 1px 3px 0px #00000026;
  white-space: nowrap;
  font-family: ${fonts.primary};
  background-color: ${theme.greenLighter};
  color: ${theme.green};
  ${props =>
    props.background &&
    css`
      background-color: ${props.background};
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}
`;

export const GoalIcon = styled.img`
  width: 20px;
`;

export const CardBody = styled.div`
  padding: 15px 15px 30px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CardTextMd = styled.div`
  font-size: 14px;
  font-weight: 300;
`;

export const CardTextSm = styled.div`
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 6px;
`;

export const CardTextXs = styled.div`
  font-size: 8px;
  font-weight: 300;
  white-space: nowrap;
`;

export const RecommendItems = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 320px;
  margin-top: 30px;
`;

export const RecommendItem = styled.div`
  max-width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const ItemImage = styled.img`
  max-height: 111px;
  margin-bottom: 12px;
`;
