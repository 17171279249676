import {useCallback, useContext} from 'react';
import {trackPageView,trackIdentify, trackEvent} from "../services/tracking";
import {GlobalContext} from "../context/GlobalContext";
import {setCookie} from "../services/cookies";
import {COOKIE_EXPIRES_IN} from "../constants/cookies";

const useTracking = () => {
  const {hasTrackedEvent, setHasTrackedEvent} = useContext(GlobalContext);

  const getUtmParams = () => {
    let utmParams = {
      context: {
        campaign: {
          campaignId: null,
          name: null,
          source: null,
          medium: null,
          term: null,
          content: null
        }
      }
    };
    const location = window?.location;
    const urlParams = new URLSearchParams(location.search);

    urlParams.forEach((value, key) => {
      if (key.startsWith('utm_')) {
        utmParams.context.campaign[key.slice(4)] = value;
        setCookie(key, value, COOKIE_EXPIRES_IN.maxAge);
      }
    });

    return utmParams;
  };

  const trackLandingPageView = useCallback(() => {
    !hasTrackedEvent.landingPageViewed && trackPageView('Landing', 'Landing Page Viewed', getUtmParams());
    setHasTrackedEvent({...hasTrackedEvent, landingPageViewed: true});
  }, [hasTrackedEvent, setHasTrackedEvent])

  const trackResultPageView = useCallback(() => {
    !hasTrackedEvent.resultPageViewed && trackPageView('Result', 'Result Page Viewed', getUtmParams());
    setHasTrackedEvent({...hasTrackedEvent, resultPageViewed: true});
  }, [hasTrackedEvent, setHasTrackedEvent])


  return {
    trackLandingPageView,
    trackResultPageView,
    hasTrackedEvent,
    setHasTrackedEvent,
    trackIdentify,
    trackEvent
  };
};

export {useTracking};
