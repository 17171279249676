import React from 'react';
import { FaqSection, FaqContent, FaqHeading, FaqQuestions, FaqQuestionItem, Question, Answer } from './faqStyles';

const Faq = React.memo(({ id }) => (
  <FaqSection id={id}>
    <FaqContent>
      <FaqHeading>Frequently asked questions</FaqHeading>
      <FaqQuestions>
        <FaqQuestionItem>
          <Question>How are recommendations personalised for me?</Question>
          <Answer>
            The recommendation engine has been developed by a team of nutritionists and dieticians, and recommends a
            personalised offering of vitamins and supplements suitable to support your wellness goals.
          </Answer>
        </FaqQuestionItem>
        <FaqQuestionItem>
          <Question>How long does the assessments take?</Question>
          <Answer>
            The length of the assessment varies depending on the area(s) you want to manage, but typically takes 5
            minutes to complete.
          </Answer>
        </FaqQuestionItem>
        <FaqQuestionItem>
          <Question>Can I buy my recommended supplements online?</Question>
          <Answer>Yes, you can add your recommended vitamins and supplements to your basket.</Answer>
        </FaqQuestionItem>
      </FaqQuestions>
    </FaqContent>
  </FaqSection>
));

export default Faq;
