import {getCookie} from './cookies';
import {print} from 'graphql';
import {COOKIES} from '../constants/cookies';
import {useState, useEffect} from 'react';

export const useFetch = query =>
  fetch(`${process.env.REACT_APP_X_API}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': `${process.env.REACT_APP_X_API_KEY}`,
      authorization: getCookie(COOKIES.token) || '',
      'x-store-code': getCookie(COOKIES.currency) || 'GBP',
    },
    body: JSON.stringify({
      query: print(query),
    }),
  }).then(res => res.json());

export const useTitle = (text) => {
  const [title, setTitle] = useState(text);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return [title, setTitle];
};
