import { css } from 'styled-components';

const sizes = {
  screenSm: 576,
  screenMd: 768,
  screenLg: 992,
  screenXl: 1200,
  screenXxl: 1440,
};

// Iterate through the sizes and create a media template
export const from = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

// Iterate through the sizes and create a media template
export const to = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;

  return acc;
}, {});

export const media = {
  from,
  to,
};
