import gql from 'graphql-tag';

const fragmentProducts = gql`
  fragment products on ProductCategory {
    products {
      type
      id
      productId
      score
      name
      subtitle
      thumbnailUrl
      rrpPrice
      offerPrice
      offerText
      associatedGoals {
        id
        name
        color
        iconUrl
        score
      }
      nutrients {
        id
        name
        value
      }
      default
    }
  }
`;

const fragmentNutrientUpperLimits = gql`
  fragment nutrientUpperLimits on Config {
    nutrientUpperLimits {
      id
      name
      value
    }
  }
`;

export const GET_HEALTH_PROFILE = gql`
  ${fragmentProducts}
  ${fragmentNutrientUpperLimits}
  query healthProfile {
    config {
      bundleInitialMaxItemCount
      ...nutrientUpperLimits
    }
    healthProfile {
      recommendedProductCategories {
        id
        type
        name
        score
        description
        ...products
      }
      goals {
        id
        name
        color
        iconUrl
        score
        backgroundColor
      }
      warnings {
        id
        name
      }
    }
  }
`;

export const GET_HEALTH_PROFILE_HISTORICAL = gql`
  ${fragmentProducts}
  ${fragmentNutrientUpperLimits}
  query historicalHealthProfile($chatId: String!) {
    config {
      bundleInitialMaxItemCount
      ...nutrientUpperLimits
    }
    historicalHealthProfile(chatId: $chatId) {
      recommendedProductCategories {
        id
        type
        name
        score
        description
        ...products
      }
      goals {
        id
        name
        color
        iconUrl
        score
        backgroundColor
      }
      warnings {
        id
        name
      }
    }
  }
`;
