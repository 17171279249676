import React from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorMessage, RecommendedCategory } from '../Result/components/RecommendedCategories/Category/categoryStyles';
import {
  RecommendSection,
  RecommendContent,
} from '../Result/components/RecommendedCategories/recommendedCategoriesStyles';
import {useTitle} from "../../../services/useHooks";

const NoMatch = () => {
  useTitle('404: Clicks Supplement Recommender')

  let location = useLocation();

  return (
    <RecommendSection>
      <RecommendContent>
        <RecommendedCategory>
          <ErrorMessage>
            Nothing found at <code>{location.pathname}</code>
          </ErrorMessage>
        </RecommendedCategory>
      </RecommendContent>
    </RecommendSection>
  );
};

export default NoMatch;
