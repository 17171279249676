import React, { useContext } from 'react';
import { ModalContainer, ModalDialog, ModalContent, ModalTitle, ModalDesc, CloseCross } from './modalStyles';
import CloseCrossImg from '../../assets/images/cross.svg';
import { GlobalContext } from '../../context/GlobalContext';

const Modal = () => {
  const { modal, setModal } = useContext(GlobalContext);

  return (
    <ModalContainer isOpen={modal.show}>
      <ModalDialog isOpen={modal.show}>
        <ModalContent>
          <CloseCross src={CloseCrossImg} onClick={() => setModal({ show: false, message: '', title: '' })} />
          <ModalTitle>{modal.title}</ModalTitle>
          <ModalDesc>{modal.message}</ModalDesc>
        </ModalContent>
      </ModalDialog>
    </ModalContainer>
  );
};

export default Modal;
