import React from 'react';
import {
  FooterComponent,
  FooterContent,
  CustomerServiceCenter,
  FooterLinks,
  PhoneNumber,
  FooterLink,
} from './partialStyles';

const Footer = React.memo(({ id }) => (
  <FooterComponent id={id}>
    <FooterContent>
      <CustomerServiceCenter>
        Customer Service Centre: <PhoneNumber href="tel: 0860 254 257">0860 254 257</PhoneNumber> | Outside South
        Africa: <PhoneNumber href="tel: +27 21 460 1009">+27 21 460 1009</PhoneNumber>
      </CustomerServiceCenter>
      <FooterLinks>
        <FooterLink rel="noopener noreferrer" href="https://clicks.co.za/legalHelp" target="_blank">
          Terms & Conditions
        </FooterLink>{' '}
        |
        <FooterLink rel="noopener noreferrer" href="https://clicks.co.za/help" target="_blank">
          Help
        </FooterLink>{' '}
        |
        <FooterLink rel="noopener noreferrer" href="https://clicks.co.za/legalHelp" target="_blank">
          Legal
        </FooterLink>{' '}
        |
        <FooterLink rel="noopener noreferrer" href="https://clicks.co.za/contactus" target="_blank">
          Contact Us
        </FooterLink>
      </FooterLinks>
    </FooterContent>
  </FooterComponent>
));

export default Footer;
