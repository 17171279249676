import React, {useContext, useEffect} from 'react';
// components
import Header from '../partial/Header';
import Intro from './Intro';
import HowItWorks from './HowItWorks';
import Faq from './Faq';
import Improve from './Improve';
import Footer from '../partial/Footer';
import Loader from '../partial/Loader';
// context
import {AuthContext} from '../../../context/AuthContext';
// styles
import {MainWrapper, InvisiblePixelImage} from './styles';
// services
import {paramChatId} from '../../../services/global';
import {useTracking} from "../../../hooks/useTracking";
import {useTitle} from "../../../services/useHooks";

const Landing = () => {
  useTitle('Clicks Supplement Recommender');

  const {vitlId, loadingUserInfo, loadingCreateGuest, userRequestInfo, isPollingUserInfo} =
    useContext(AuthContext);

  const {trackLandingPageView, hasTrackedEvent} = useTracking();

  // Abandoned consultation handling
  useEffect(() => {
    if (paramChatId) {
      userRequestInfo({
        variables: {
          chatId: paramChatId,
        },
      });
    }
  }, [userRequestInfo]);

  useEffect(() => {
    if (!hasTrackedEvent.landingPageViewed) {
      trackLandingPageView()
    }
  });

  const loading = isPollingUserInfo || loadingUserInfo || loadingCreateGuest;

  if (loading)
    return (
      <MainWrapper>
        {vitlId && (
          <InvisiblePixelImage
            src={`${process.env.REACT_APP_X_CLICKS_PIXEL_URL}${vitlId}`}
          />
        )}
        <Loader/>
      </MainWrapper>
    );

  return (
    <MainWrapper>
      <Header id="mainHeader"/>
      <Intro id="sectionIntro"/>
      <Improve id="sectionImprove"/>
      <HowItWorks id="sectionHowItWorks"/>
      <Faq id="sectionFaq"/>
      <Footer id="mainFooter"/>
    </MainWrapper>
  );
};

export default Landing;
