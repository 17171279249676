import styled from 'styled-components';
import { fonts, theme } from '../../../../../theme';
import { media } from '../../../../../utilities/grid';

export const SelectedProductsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${theme.white};
  padding: 15px 15px 15px 15px;
  height: auto;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.15);
  box-sizing: bordered-box;
  z-index: 10;
  ${media.from.screenSm`
    flex-direction: row;
    padding: 0 35px;
    justify-content: space-between;
    height: 89px;
  `}
`;
export const SelectedItemsText = styled.p`
  font-family: ${fonts.primary};
  font-size: 14px;
  font-weight: 400;
  color: ${theme.textBlack};
  margin-bottom: 10px;
  ${media.from.screenSm`
      margin-bottom: 0;
  `}
`;
export const NumberOfItems = styled.span`
  font-weight: 600;
  color: ${theme.clicksBlue};
`;

export const ItemsTotalPrice = styled.span`
  font-weight: 600;
  color: ${theme.clicksBlue};
`;
