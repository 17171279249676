import styled from 'styled-components';
import { containers, theme, fonts } from '../../../theme';
import { media } from '../../../utilities/grid';
import HeroImage from '../../../assets/images/hero.png';

export const IntroSection = styled.section`
  background-color: ${theme.blueBackground};
  pointer-events: none;
  padding-top: 30px;

  ${media.from.screenXl`
    padding-top: 62px;
  `}
`;

export const IntroContent = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: flex-start;
  flex-wrap: nowrap;
  z-index: 1;
  max-width: ${containers.Lg};
  height: 750px;
  ${media.from.screenXl`
    max-width: ${containers.Xl};
    flex-direction: row;
    justify-content: space-between;
    background-position: right;
    height: 350px;
    flex-wrap: wrap;
  `}

  ${media.from.screenXxl`
    max-width: ${containers.Xxl};
  `}
`;

export const IntroContentText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${theme.bootsBlue};
  align-items: center;
  padding: 0 25px;
  box-sizing: border-box;

  ${media.from.screenXl`
    align-items: flex-start;
    line-height: 1.5;
    padding: 0;
    width: 50%;
  `}
`;

export const IntroContentImages = styled.div`
  width: 100%;
  display: flex;
  ${`background-image: url(${HeroImage})`};
  background-repeat: no-repeat;
  background-size: contain;
  height: 20rem;
  background-position: center;
  position: relative;
  margin-bottom: 34px;

  ${media.from.screenXl`
    background-position: top right;
    width: 50%;
    height: 640px;
    margin-bottom: 0;
    transform: translateY(-30%);
  `}
`;

export const IntroTextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem 0 8.75rem 0;

  ${media.from.screenXl`
    width: 48%;
    text-align: left;
    padding: 0;
    margin-top: 63px;
    justify-content: flex-start;
  `}
`;

export const IntroUpperText = styled.h1`
  font-size: 36px;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 30px;
  font-weight: bold;
  text-align: center;
  font-family: ${fonts.secondary};

  ${media.from.screenXl`
    text-align: left;
  `}
`;

export const IntroBelowText = styled.p`
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  margin-bottom: 30px;
  max-width: 494px;
  ${media.from.screenXl`
    font-size: 16px;
    text-align: left;
  `}
`;

export const Image = styled.img`
  width: 313px;
  margin-bottom: 42px;
`;
