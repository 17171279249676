import styled, { css } from 'styled-components';
import { media } from './utilities/grid';

export const theme = {
  pinkBackground: '#fadad3',
  bootsBlue: '#05054b',
  error: '#C03',
  success: '#00a696',
  white: '#ffffff',
  greenBlue: '#d6f1ee',
  borderGreen: '#8DC63F',
  textGrey: '#616161',
  mustardBackground: '#fceed3',
  lightGrey: '#f2f2f2',
  greenBackground: '#7BBB5B',
  lightGreen: '#E7F5D4',
  textBlack: '#212121',
  textBlackSecondary: '#222222',
  textBlue: '#003268',
  textBlueTitle: '#113265',
  textBlueSecondary: '#005AAA',
  borderGrey: '#CCCCCC',
  blueBackground: '#E6F7FD',
  blueText: '#00AEEF',
  blueCardBackground: '#F2FBFF',
  clicksBlue: '#005BAA',
  clicksBlueDark: '#003268',
  lightGreenBackground: '#EAF3DB',
  pink: '#DBB6D0',
  green: '#84BF41',
  greenLighter: '#E8F4D9',
  red: '#E3193A',
  redBackground: '#FCF2F2',
};

export const fonts = {
  primary: 'roboto',
  secondary: 'itc-avant-garde-gothic-pro',
};

export const defaultBackground = css`
  background-color: ${theme.pinkBackground};
`;

export const sizes = {
  maxWidth: '1440px',
};

export const containers = {
  Lg: '960px',
  Xl: '1140px',
  Xxl: '1335px',
};

export const bodyText = styled.p`
  font-size: 18px;
  ${media.from.screenXl`
    font-size: 22px;
  `}
`;

export const headerBold = styled.h3`
  font-size: 28px;
  ${media.from.screenXl`
    font-size: 36px;
  `}
`;

export const bodyTitleSmall = styled.p`
  font-size: 14px;
  font-weight: bold;
  ${media.from.screenXl`
    font-size: 30px;
  `}
`;

export const bodySmallText = styled.p`
  font-size: 14px;
  ${media.from.screenXl`
    font-size: 18px;
  `}
`;

export const sectionTitle = styled.h2`
  font-weight: 600;
  margin-top: 0;
  color: ${theme.textBlueTitle};
  font-family: ${fonts.secondary};
  ${media.from.screenXl`
    font-size: 30px;
  `}
`;
