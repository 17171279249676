import React, {useEffect, useContext, useRef, useReducer, useCallback} from 'react';
import {useLazyQuery} from 'react-apollo';
import {useNavigate} from 'react-router-dom';
// reducer
import ResultsReducer from './resultsReducer';
import {
  DESELECT_PRODUCT,
  SELECT_ADDITIONAL_PRODUCT,
  SELECT_CATEGORY,
  SELECT_PRODUCT,
  SET_HEALTH_PROFILE,
  SET_RECOMMENDED_CATEGORIES,
  SWITCH_PRODUCT,
} from './types';
// queries
import {GET_HEALTH_PROFILE, GET_HEALTH_PROFILE_HISTORICAL} from '../queries/products';
// context
import {GlobalContext} from './GlobalContext';
// services
import {textCapitalize} from '../services/format';
import {
  calcNutrientsOnSet,
  removeBasketNutrients,
  scrollToCategory,
  shouldAddProduct,
  shouldShowTULModal,
} from '../services/products';
import {WARNINGS} from '../constants/warnings';
import {GRAPH_QL_ERROR_TYPES} from '../constants/errorTypes';
import {useTracking} from "../hooks/useTracking";

export const ResultsContext = React.createContext(null);

export const ResultsContextProvider = props => {
  const recommendedCategoriesRef = useRef(null);

  // state
  const initialState = {
    healthProfile: {},
    config: {},
    recommendedCategories: [],
    basketNutrients: [],
    selectedProducts: {},
    allAdditionalCategories: [],
    additionalCategoryProducts: null,
    categoriesNavItems: [],
    selectedCategory: 'viewAll',
  };

  const [state, dispatch] = useReducer(ResultsReducer, initialState);

  const {hasTrackedEvent, setHasTrackedEvent, trackEvent} = useTracking();

  const {
    healthProfile,
    config,
    basketNutrients,
    recommendedCategories,
    allAdditionalCategories,
    additionalCategoryProducts,
    categoriesNavItems,
    selectedCategory,
    selectedProducts,
  } = state;

  const {warnings, goals} = healthProfile;

  const {bundleInitialMaxItemCount, nutrientUpperLimits} = config;

  // context
  const {setModal} = useContext(GlobalContext);

  // modals
  const showTulModal = useCallback(
    nutrient => {
      setModal({
        show: true,
        title: 'Please note',
        message: (
          <>
            You’ve reached your maximum daily allowance of <b>{textCapitalize(nutrient)}</b>
          </>
        ),
      });
    },
    [setModal]
  );
  const showWarningModal = useCallback(
    warnings => {
      setModal({
        show: true,
        title: 'Please note',
        message: (
          <>
            <div>
              During your consultation you mentioned that you:
              <ul>
                {warnings.map((w, i) => (
                  <li key={i}>{WARNINGS[w.name]}</li>
                ))}
              </ul>
              We strongly advise that you seek medical advice from a healthcare professional to ensure that the
              recommendation is suitable for you.
            </div>
          </>
        ),
      });
    },
    [setModal]
  );

  // queries
  const [getHealthProfile, {loading: loadingHealthProfileActual, error: errorGetHealthProfileActual}] = useLazyQuery(
    GET_HEALTH_PROFILE,
    {
      fetchPolicy: 'no-cache',
      onCompleted: data => {
        console.log('nutrientUpperLimits: ', data.config?.nutrientUpperLimits);
        dispatch({
          type: SET_HEALTH_PROFILE,
          payload: data,
        });
      },
      onError: e => handleGetHealthProfileErr(e),
    }
  );

  const [
    getHealthProfileHistorical,
    {loading: loadingHealthProfileHistorical, error: errorGetHealthProfileHistorical},
  ] = useLazyQuery(GET_HEALTH_PROFILE_HISTORICAL, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      dispatch({
        type: SET_HEALTH_PROFILE,
        payload: {
          healthProfile: data.historicalHealthProfile,
          config: data.config,
        },
      });
    },
    onError: e => handleGetHealthProfileErr(e),
  });

  const navigate = useNavigate();
  const handleGetHealthProfileErr = error => {
    const findError = error.graphQLErrors.find(x => GRAPH_QL_ERROR_TYPES[x.errorType]);
    if (findError?.errorType === GRAPH_QL_ERROR_TYPES.Unauthorized) {
      navigate('/');
    }
  };

  if (!healthProfile) window.location.assign('/');

  useEffect(() => {
    if (warnings?.length > 0) {
      showWarningModal(warnings);
    }
  }, [warnings, showWarningModal]);

  useEffect(() => {
    if (healthProfile && healthProfile.recommendedProductCategories) {
      let basketNutrientsTemp = [];
      const recommendedCategoriesTemp = [];
      const additionalCategoriesTemp = [];
      const selectedProductsTemp = {};
      const recommendedProductIds = [];

      const calcShouldAddToRecommended = product => {
        for (let i = 0; i < product.nutrients.length; i++) {
          let nutrient = product.nutrients[i];
          if (nutrient.value) {
            let shouldShowTULModalResult = shouldShowTULModal(nutrient, basketNutrientsTemp, nutrientUpperLimits);
            if (shouldShowTULModalResult) {
              return false;
            } else {
              basketNutrientsTemp = calcNutrientsOnSet(nutrient, basketNutrientsTemp);
            }
          }
        }
        return true;
      };

      for (let j = 0; j < healthProfile.recommendedProductCategories.length; j++) {
        let category = healthProfile.recommendedProductCategories[j];
        let defaultProduct = category.products.find(product => product.default);
        if (!defaultProduct) {
          additionalCategoriesTemp.push(category);
        } else {
          if (recommendedCategoriesTemp.length >= bundleInitialMaxItemCount) {
            additionalCategoriesTemp.push(category);
          } else {
            if (calcShouldAddToRecommended(defaultProduct)) {
              recommendedCategoriesTemp.push(category);
              selectedProductsTemp[category.id] = defaultProduct;
              recommendedProductIds.push(defaultProduct.productId);
            } else{
              additionalCategoriesTemp.push(category);
            }
          }
        }
      }

      sessionStorage.setItem('health_profile', JSON.stringify({
        recommendedCategories: recommendedCategoriesTemp,
        additionalCategories: additionalCategoriesTemp
      }));

      if (recommendedProductIds?.length > 0 && !hasTrackedEvent.recommendedProducts) {
        trackEvent('Wellness Recommended Products', {
          product_ids: recommendedProductIds
        })
        setHasTrackedEvent({...hasTrackedEvent, recommendedProducts: true})
      }

      dispatch({
        type: SET_RECOMMENDED_CATEGORIES,
        payload: {
          recommendedCategories: recommendedCategoriesTemp,
          selectedProducts: selectedProductsTemp,
          allAdditionalCategories: additionalCategoriesTemp,
          categoriesNavItems: additionalCategoriesTemp,
          basketNutrients: basketNutrientsTemp,
        },
      });
    }
  }, [trackEvent, hasTrackedEvent, setHasTrackedEvent, healthProfile, bundleInitialMaxItemCount, nutrientUpperLimits]);

  const handleSelectProduct = (category, product) => {
    const prevProduct = selectedProducts[category.id];
    const nextProduct = product;
    if (!prevProduct) {
      // Select a product from a recommended category when non is selected from that category
      const resultShouldAddProduct = shouldAddProduct(nextProduct, basketNutrients, nutrientUpperLimits);
      if (!resultShouldAddProduct.result && resultShouldAddProduct.errorNutrient) {
        showTulModal(resultShouldAddProduct.errorNutrient);
      } else {
        dispatch({
          type: SELECT_PRODUCT,
          payload: {
            product: nextProduct,
            categoryId: category.id,
          },
        });
      }
    } else {
      if (nextProduct.productId !== prevProduct.productId) {
        // Select another product from the same recommended category while one product is already selected
        // Check if next product is eligible to select then remove nutrients of the current product
        const basketNutrientsTemp = [...removeBasketNutrients(prevProduct, basketNutrients)];
        const resultShouldSwitchProduct = shouldAddProduct(nextProduct, basketNutrientsTemp, nutrientUpperLimits);

        if (!resultShouldSwitchProduct.result && resultShouldSwitchProduct.errorNutrient) {
          showTulModal(resultShouldSwitchProduct.errorNutrient);
        } else {
          dispatch({
            type: SWITCH_PRODUCT,
            payload: {
              prevProduct,
              nextProduct,
              categoryId: category.id,
            },
          });
        }
      } else {
        // Deselect the selected product from a recommended category, non will be selected
        dispatch({
          type: DESELECT_PRODUCT,
          payload: {
            category,
            product: prevProduct,
          },
        });
      }
    }
  };

  const handleSelectAdditionProduct = (categoryId, product) => {
    const resultShouldAddProduct = shouldAddProduct(product, basketNutrients, nutrientUpperLimits);
    if (!resultShouldAddProduct.result && resultShouldAddProduct.errorNutrient) {
      showTulModal(resultShouldAddProduct.errorNutrient);
    } else {
      dispatch({
        type: SELECT_ADDITIONAL_PRODUCT,
        payload: {
          product,
          categoryId,
        },
      });
      scrollToCategory(recommendedCategoriesRef);
    }
  };

  const handleSelectCategory = category => {
    dispatch({
      type: SELECT_CATEGORY,
      payload: {
        category,
      },
    });
  };

  useEffect(() => {
    console.log('basketNutrients: ', basketNutrients);
  }, [basketNutrients]);

  const loadingHealthProfile = loadingHealthProfileActual || loadingHealthProfileHistorical;
  const errorGetHealthProfile = errorGetHealthProfileActual || errorGetHealthProfileHistorical;

  return (
    <ResultsContext.Provider
      value={{
        getHealthProfile,
        getHealthProfileHistorical,
        goals,
        recommendedCategories,
        selectedProducts,
        allAdditionalCategories,
        additionalCategoryProducts,
        handleSelectProduct,
        handleSelectCategory,
        handleSelectAdditionProduct,
        categoriesNavItems,
        selectedCategory,
        loadingHealthProfile,
        errorGetHealthProfile,
        recommendedCategoriesRef,
      }}
    >
      {props.children}
    </ResultsContext.Provider>
  );
};
