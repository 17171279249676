import styled from 'styled-components';
import { fonts, theme } from '../../../../../../theme';
import { media } from '../../../../../../utilities/grid';

export const RecommendedCategory = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 0px 24px 34px;
  background-color: ${theme.white};
  margin: 15px;
  margin-bottom: 86px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  ${media.from.screenXl`
    padding: 0px 44px 34px;
  `}
`;

export const RecommendedCategoryTitleWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -24px;
  margin-bottom: 50px;
  width: 100%;
  max-width: 257px;
  height: 45px;
  padding: 4px 14px;
  text-align: center;
  border: 1px solid ${theme.borderGreen};
  background-color: ${theme.white};
  border-radius: 30px;
  ${media.from.screenMd`
     min-width: 372px;
     max-width: fit-content;
  `}
`;

export const RecommendedCategoryTitle = styled.h2`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  font-family: ${fonts.secondary};
  color: ${theme.clicksBlueDark};
  margin: 0;
`;

export const Info = styled.img`
  display: flex;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
`;

export const SwiperWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const ErrorMessage = styled.h2`
  font-family: ${fonts.primary};
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 0;
`;
