import React, {useState, useContext, useEffect} from 'react';
import {useMutation, useQuery} from 'react-apollo';
import {v4 as uuidv4} from 'uuid';
import client from "../clients/axios";
// context
import {GlobalContext} from './GlobalContext';
// queries
import {USER_REQUEST_TOKEN, GUEST_CREATE, USER_CLICKS_INFO} from '../queries/user';
// services
import {getCookie, setCookie} from '../services/cookies';
// constants
import {COOKIE_EXPIRES_IN, COOKIES} from '../constants/cookies';
import {useTracking} from "../hooks/useTracking";

export const AuthContext = React.createContext(null);

export const AuthContextProvider = props => {
  const {showConsultation, setShowConsultation} = useContext(GlobalContext);
  const {trackIdentify, trackEvent} = useTracking();

  // state
  const [vitlId, setVitlId] = useState(null);
  const [skipPolling, setSkipPolling] = useState(true);
  const [isPollingUserInfo, setIsPollingUserInfo] = useState(true);
  const [shouldStopPolling, setShouldStopPolling] = useState(false);

  //queries
  const [createGuest, {loading: loadingCreateGuest}] = useMutation(GUEST_CREATE, {
    onCompleted: res =>
      res?.guest_create && setCookie(COOKIES.guestToken, res.guest_create, COOKIE_EXPIRES_IN.thirtyDays),
  });

  const {
    data: dataUserInfo,
    loading: loadingUserInfo,
    startPolling,
    stopPolling,
  } = useQuery(USER_CLICKS_INFO, {
    skip: skipPolling,
    variables: {vitlId}
  });

  const getUserVitlInfo = async () => {
    const query = `
        query {
          user_vitlInfo {
            id
            firstName
            email
            clubCardNumber
          }
        }
      `

    try {
      const response = await client.post('',{query}, {
        headers: {
          'Authorization': `Bearer ${getCookie(COOKIES.token)}`,
        },
      });
      const email = response?.data?.data?.user_vitlInfo?.email?.toLowerCase();
      email && trackIdentify(email, { clicksUser: false, user_uid: email });
    } catch (error) {
      console.error('Error fetching user vital info:', error);
    }
  };

  useEffect(() => {
    const userClicksInfo = dataUserInfo?.user_clicksInfo;
    if (userClicksInfo) {
      const {id, email, token} = userClicksInfo;
      if (id && token) {
        trackIdentify(id, { clicksUser: true, user_uid: email.toLowerCase(), userPersonId: id })
        trackEvent('ClicksUserAutoLogin')
        setCookie(COOKIES.token, token, COOKIE_EXPIRES_IN.thirtyDays);
      } else {
        if (!getCookie(COOKIES.guestToken)) {
          createGuest();
        }
      }
    }
  }, [dataUserInfo, createGuest, trackEvent, trackIdentify]);

  useEffect(() => {
    setVitlId(uuidv4());
    setSkipPolling(false);
  }, [setVitlId, setSkipPolling]);

  useEffect(() => {
    if (vitlId && !skipPolling) {
      startPolling(500);
    }
  }, [vitlId, skipPolling, startPolling]);

  useEffect(() => {
    if (shouldStopPolling || dataUserInfo) {
      stopPolling();
      setIsPollingUserInfo(false);
    }
  }, [vitlId, dataUserInfo, stopPolling, shouldStopPolling, setIsPollingUserInfo]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!showConsultation || !dataUserInfo) {
        setShouldStopPolling(true);
        if (!getCookie(COOKIES.guestToken) && !getCookie(COOKIES.token)) {
          createGuest()
        }
      }
    }, 5000);
    return () => clearTimeout(timer);
  }, [setShouldStopPolling, createGuest, showConsultation, dataUserInfo]);

  const [userRequestInfo] = useMutation(USER_REQUEST_TOKEN, {
    onCompleted: data => {
      if (data?.user_requestToken) {
        setCookie(COOKIES.token, data.user_requestToken, COOKIE_EXPIRES_IN.thirtyDays);
        setShowConsultation(true);
      }
    },
  });

  return (
    <AuthContext.Provider
      value={{
        vitlId,
        loadingUserInfo,
        loadingCreateGuest,
        userRequestInfo,
        isPollingUserInfo,
        createGuest,
        getUserVitlInfo,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
