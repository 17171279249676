import React from 'react';
//services
import { ButtonSelect } from '../../../../../AppStyles';
//styles
import {
  ProductGoal,
  ProductGoalIcon,
  ProductGoals,
  ProductPrice,
  ProductItem,
  ProductDesc,
  Price,
  PromotionText,
  ProductImage,
  ProductTitle,
} from './productStyles';
import { formatPrice } from '../../../../../services/format';

const Product = ({ data, selectProduct, isSelected, id }) => {
  return (
    <ProductItem className={isSelected ? 'isSelected' : ''} isSelected={isSelected} onClick={selectProduct} id={id} data-test-id={data.id}>
      <ProductImage src={data.thumbnailUrl} />
      <ProductTitle>{data.name}</ProductTitle>
      <ProductDesc>{data.subtitle}</ProductDesc>
      <ProductPrice>
        <Price id="productPrice" hasOffer={data.offerPrice}>
          {data.offerPrice ? formatPrice(data.offerPrice) : formatPrice(data.rrpPrice)}
        </Price>
        {data.offerText && data.offerPrice && <PromotionText>{data.offerText}</PromotionText>}
      </ProductPrice>
      <ButtonSelect isSelected={isSelected}>{isSelected ? 'Selected Item' : 'Select Item'}</ButtonSelect>
      <ProductGoals>
        {data.associatedGoals?.length > 0 &&
          data.associatedGoals.map((goal, goalIndex) => (
            <ProductGoal key={goalIndex}>
              <ProductGoalIcon src={goal.iconUrl} />
            </ProductGoal>
          ))}
      </ProductGoals>
    </ProductItem>
  );
};

export default Product;
