import styled from 'styled-components';
import { theme, fonts } from '../../../../../theme';
import { media } from '../../../../../utilities/grid';

export const IntroSection = styled.section`
  background-color: ${theme.blueBackground};
  pointer-events: none;
  position: relative;
  padding-top: 62px;
`;

export const IntroOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-style: solid;
    border-color: transparent;
    border-width: 2vh 50vw;
    border-bottom-color: ${theme.white};
    border-right-color: ${theme.white};
    z-index: 0;
  }
`;

export const IntroContent = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  z-index: 1;
  max-width: 1065px;
  height: 420px;

  ${media.from.screenMd`
    height: 360px;
  `}
  ${media.from.screenXl`
    height: 237px;
    justify-content: space-between;
  `}
`;

export const IntroText = styled.div`
  max-width: 540px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 40px;
`;

export const IntroTitle = styled.h1`
  font-family: ${fonts.secondary};
  font-family: 32px;
  font-weight: 400;
  color: ${theme.clicksBlueDark};
`;

export const IntroDescription = styled.p`
  font-family: ${fonts.primary};
  font-family: 16px;
  font-weight: 300;
  color: ${theme.textBlack};
  line-height: 22px;
`;

export const IntroGoals = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const GoalDesc = styled.p``;
