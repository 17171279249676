import styled, { css } from 'styled-components';
import { fonts, theme } from './theme';
import { Link } from 'react-router-dom';

export const MainApp = styled.div`
  width: 100%;
  margin: 0 auto;
`;

export const LinkButton = styled(Link)`
  display: flex;
  width: 12.2rem;
  height: 2rem;
  background: ${theme.bootsBlue};
  padding: 0.3rem;
  color: ${theme.white};
  border-radius: 0.25rem;
  justify-content: center;
  align-items: center;
`;

export const Button = styled.button`
  font-family: 'roboto';
  display: flex;
  background: ${theme.clicksBlue};
  height: 48px;
  padding: 0 60px;
  color: ${theme.white};
  border: none;
  border-radius: 23px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  pointer-events: auto;
  white-space: nowrap;
`;

export const StickyLinkWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  overflow: hidden;
  bottom: 0;
  padding: 1rem 0;
  background-color: ${theme.bootsBlue};
  ${Button} {
    border: 1px solid ${theme.white};
  }
`;

export const ButtonSecondary = styled.a`
  display: flex;
  background: ${theme.white};
  height: 44px;
  padding: 0 16px;
  color: ${theme.textBlack};
  border: 1px solid ${theme.borderGrey};
  border-radius: 23px;
  justify-content: center;
  align-items: center;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;
  pointer-events: auto;
  white-space: nowrap;
  transition: border 0.2s linear;
  &:hover {
    color: ${theme.textBlack};
    border: 1px solid ${theme.textBlack};
  }
`;

export const ButtonSelect = styled.button`
  display: flex;
  background: ${theme.white};
  height: 34px;
  padding: 0 16px;
  color: ${theme.clicksBlue};
  border: 1px solid ${theme.clicksBlue};
  border-radius: 23px;
  justify-content: center;
  align-items: center;
  font-size: inherit;
  font-family: ${fonts.primary};
  font-weight: 400;
  cursor: pointer;
  pointer-events: auto;
  white-space: nowrap;
  transition: all 0.15s ease-in;
  ${props =>
    props.isSelected &&
    css`
      border: 1px solid ${theme.green};
      color: ${theme.white};
      background-color: ${theme.green};
    `}
`;
