import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
// styles
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './assets/fonts/fonts';
import { MainApp } from './AppStyles';
import './main.css';
// context
import { GlobalContextProvider } from './context/GlobalContext';
import { AuthContextProvider } from './context/AuthContext';
import { ResultsContextProvider } from './context/ResultsContext';
// components
import Loader from './components/views/partial/Loader';
import Modal from './components/modal';
import { theme } from './theme';
import NoMatch from './components/views/404';
import Landing from './components/views/Landing';
import Result from './components/views/Result';
// services
import { initialiseRudderAnalytics } from './services/tracking';

function App() {
  initialiseRudderAnalytics();
  return (
    <ThemeProvider theme={{ ...theme, fontFamily: 'roboto' }}>
      <GlobalStyle />
      <MainApp>
        <GlobalContextProvider>
          <AuthContextProvider>
            <ResultsContextProvider>
              <Suspense fallback={<Loader />}>
                <Routes>
                  <Route exact path="/" element={<Landing />} />
                  <Route exact path="/result" element={<Result />} />
                  <Route path="*" element={<NoMatch />} />
                </Routes>
              </Suspense>

              <Modal />
            </ResultsContextProvider>
          </AuthContextProvider>
        </GlobalContextProvider>
      </MainApp>
    </ThemeProvider>
  );
}

export default App;
