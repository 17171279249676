import styled, { css } from 'styled-components';
import { theme, fonts } from '../../../theme';

export const ImproveSection = styled.section`
  background-color: ${theme.white};
  padding: 134px 0 74px;
  position: relative;
  overflow-x: hidden;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    border-style: solid;
    border-color: transparent;
    border-width: 5vh 50vw;
    border-top-color: ${theme.blueBackground};
    border-left-color: ${theme.blueBackground};
    z-index: 0;
  }
`;

export const ImproveContent = styled.div`
  padding: 0 25px;
`;

export const ImproveDesc = styled.p`
  font-size: 18px;
  margin-bottom: 30px;
  text-align: center;
`;
export const Goals = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
`;

export const Goal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 280px;
  height: 60px;
  border-radius: 32px;
  padding-left: 24px;
  padding-right: 14px;
  font-size: 20px;
  margin: 15px;
  font-weight: 600;
  box-shadow: 0px 1px 3px 0px #00000026;
  font-family: ${fonts.primary};
  background-color: ${theme.greenLighter};
  color: ${theme.green};
  ${props =>
    props.background &&
    css`
      background-color: ${props.background};
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}
`;

export const Image = styled.img`
  width: 36px;
`;
