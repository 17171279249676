import gql from 'graphql-tag';

export const GUEST_CREATE = gql`
  mutation guest_create {
    guest_create
  }
`;

export const USER_CLICKS_INFO = gql`
  query user_clicksInfo($vitlId: String!) {
    user_clicksInfo(vitlId: $vitlId) {
      token
      id
      firstName
      email
      clubCardNumber
    }
  }
`;

export const USER_REQUEST_TOKEN = gql`
  mutation user_requestToken($chatId: String!) {
    user_requestToken(chatId: $chatId)
  }
`;
