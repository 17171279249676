import React from 'react';
import { ImproveSection, ImproveDesc, Goals, Goal, Image, ImproveContent } from './improveStyles';
import Sleep from '../../../assets/images/sleep.svg';
import Energy from '../../../assets/images/energy.svg';
import Stress from '../../../assets/images/stress.svg';
import Digestion from '../../../assets/images/digestion.svg';
import Immunity from '../../../assets/images/immunity.svg';

const GoalsItems = [
  {
    text: 'Sleep',
    icon: Sleep,
    background: '#E6F7FD',
    color: '#00AEEF',
  },
  {
    text: 'Energy',
    icon: Energy,
    background: '#E8F4D9',
    color: '#84BF41',
  },
  {
    text: 'Stress & Anxiety',
    icon: Stress,
    background: '#F1D6E8',
    color: '#B9338D',
  },
  {
    text: 'Digestion',
    icon: Digestion,
    background: '#E6EFF7',
    color: '#005BAA',
  },
  {
    text: 'Immunity',
    icon: Immunity,
    background: '#F9D1D8',
    color: '#E3193A',
  },
];

const Improve = React.memo(({ id }) => (
  <ImproveSection id={id}>
    <ImproveContent>
      <ImproveDesc>Take our assessment for recommendations to support these key wellness goals</ImproveDesc>
      <Goals>
        {GoalsItems.map((goal, i) => (
          <Goal key={i} background={goal.background} color={goal.color}>
            {goal.text}
            <Image src={goal.icon} />
          </Goal>
        ))}
      </Goals>
    </ImproveContent>
  </ImproveSection>
));

export default Improve;
