import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const domain = process.env.REACT_APP_X_COOKIES_DOMAIN;
const environment = process.env.REACT_APP_X_ENV || 'production';
const isProduction = environment === 'production';

const getEnvCookieName = function (name) {
  return isProduction ? name : environment + '_' + name;
};

export const getCookie = name => {
  return cookies.get(getEnvCookieName(name));
};

export const setCookie = (name, value) => {
  const cookieName = getEnvCookieName(name);
  removeCookie(name);
  cookies.set(cookieName, value, {
    path: '/',
    maxAge: 5 * 60 * 60,
    domain: domain,
  });
};

export const removeCookie = name => {
  const cookieName = getEnvCookieName(name);
  cookies.remove(cookieName, { path: '/' });
  cookies.remove(cookieName, { path: '/', domain: domain });
};
