import ApolloClient from 'apollo-boost';
import fetch from 'isomorphic-fetch';
import { getCookie } from '../services/cookies';
import { COOKIES } from '../constants/cookies';

const API_KEY = process.env.REACT_APP_X_API_KEY;
const API_URI = process.env.REACT_APP_X_API;

export const apolloClient = new ApolloClient({
  uri: API_URI,
  fetch,
  request: operation => {
    operation.setContext({
      headers: {
        'x-api-key': API_KEY,
        authorization: `Bearer ${getCookie(COOKIES.token)}`,
      },
    });
  },
  onError: err => {
    console.log(err);
  },
});
