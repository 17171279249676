import React, { useContext } from 'react';
import { GlobalContext } from '../../../context/GlobalContext';
import { Button } from '../../../AppStyles';
import useGoogleAnalytics from '../../../hooks/useGoogleAnalytics';

const ConsultationButton = () => {
  const { setShowConsultation } = useContext(GlobalContext);
  const {trackEvent} = useGoogleAnalytics();
  const handleConsultation = () => {
    trackEvent('wellness_clicks', 'Consultation_Started')
    setShowConsultation(true);
  };

  return <Button onClick={handleConsultation}>Take the assessment</Button>;
};

export default ConsultationButton;
