import { useEffect } from 'react';
import ReactGA from 'react-ga';

const useGoogleAnalytics = () => {
  // Google Analytics tracking ID for Clicks
  const trackingID = process.env.REACT_APP_X_CLICKS_GA_ID;

  useEffect(() => {
    // Initialize Google Analytics
    ReactGA.initialize(trackingID);
  }, [trackingID]);

  const trackPageView = (path) => {
    // Track page view
    ReactGA.pageview(path);
  };

  const trackEvent = (category, action, label, value) => {
    // Track event
    ReactGA.event({
      category,
      action,
      label,
      value,
    });
  };

  return { trackPageView, trackEvent };
};

export default useGoogleAnalytics;
